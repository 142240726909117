<template>
    <div id="atelier" class="w-100 h-100">
        <transition name="fade" mode="out-in">
            <atelier-game v-if="$store.getters.getUserAtUnit($route.name)" />
            <atelier-idle v-else />
        </transition>
    </div>
</template>

<script>
import AtelierIdle from './AtelierIdle.vue'
import AtelierGame from './AtelierGame.vue'

export default {
    name: 'AtelierMain',
    components: {
        AtelierIdle,
        AtelierGame,
    },
}
</script>

<style scoped lang="scss"></style>
