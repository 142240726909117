<template>
    <!--            TODO: Gekachelte Anordnung zeigt mehrere Ergebnisse gleichzeitig automatischer Bildwechsel-->
    <div v-if="allImages" id="atelier-idle">
        <title-and-description-idle-mode
            :title="
                $store.getters['interfaceTexts/getInterfaceText'](
                    'atelier-station-title',
                )
            "
            :description="
                $store.getters['interfaceTexts/getInterfaceText'](
                    'atelier_call_to_action',
                )
            "
        />
        <div class="grid">
            <div class="grid-item grid-item-big bg-white"></div>
            <atelier-image-crawler
                v-for="(item, index) in images"
                :key="'images_index_' + index"
                :class="randomSize(index)"
                :size="randomSize(index)"
                class="grid-item"
                :images="allImages"
                @removeImage="removeImage"
                @addImage="addImage"
            />
        </div>
    </div>
</template>

<script>
import Packery from 'packery'
import api from '../../../mixins/api'
import AtelierImageCrawler from '@/components/stations/3.005_atelier/AtelierImageCrawler'
import _ from 'lodash'
import TitleAndDescriptionIdleMode from '@/components/global/TitleAndDescriptionIdleMode'

export default {
    name: 'AtelierIdle',
    components: { TitleAndDescriptionIdleMode, AtelierImageCrawler },
    data() {
        return {
            images: null,
            allImages: null,
            height2: null,
            width2: null,
            hero: null,
        }
    },
    async mounted() {
        this.$store.dispatch('atelier/resetState')

        this.randomPositions()
        const hdImages = Object.values(
            (await api.twoavy.getAtelierImages('handdrawing'))?.data?.data,
        )
        this.allImages = _.cloneDeep(hdImages)

        if (hdImages) {
            console.log(hdImages)
            let highest = hdImages.shift()
            this.images = hdImages.splice(0, 18).sort(() => Math.random() - 0.5)
            this.images.splice(this.hero, 0, highest)

            this.$nextTick(() => {
                const elem = document.querySelector('.grid')
                new Packery(elem, {
                    itemSelector: '.grid-item',
                })
            })
        }
    },
    methods: {
        removeImage(image) {
            const index = this.allImages.findIndex(x => x.id === image.id)
            this.allImages.splice(index, 1)
        },
        addImage(image) {
            this.allImages.push(image)
        },
        randomSize(index) {
            if (this.height2.includes(index)) {
                return 'grid-item-height2'
            } else if (this.width2.includes(index)) {
                return 'grid-item-width2'
            } else if (index == this.hero) {
                return 'grid-item-big'
            }
            return ''
        },
        randomPositions() {
            const random = Math.random()
            if (random > 2 / 3) {
                this.height2 = [0, 4, 9]
                this.width2 = [1, 7, 13]
                this.hero = 5
            } else if (random > 1 / 3) {
                this.height2 = [0, 6, 8]
                this.width2 = [1, 7, 13]
                this.hero = 4
            } else {
                this.height2 = [1, 8, 10]
                this.width2 = [0, 9, 13]
                this.hero = 4
            }
        },
    },
}
</script>

<style scoped lang="scss">
@import 'src/assets/scss/global';

#atelier-idle {
    height: 100%;

    img {
        padding: 1px;
        background-color: white;
        object-fit: contain;
    }

    .grid {
        position: relative;
        width: 100vw;
        height: calc(var(--height) * 1vw);
    }

    .grid-item {
        float: left;
        width: calc(100vw / 7);
        height: calc(var(--height) / 4 * 1vw);

        &-width2 {
            width: calc(100vw / 3.5);
        }

        &-height2 {
            height: calc(var(--height) / 2 * 1vw);
        }

        &-big {
            width: calc(100vw / 3.5);
            height: calc(var(--height) / 2 * 1vw);
        }
    }
}
</style>
