<template>
    <box-wrapper
        v-if="manifest"
        id="image-gallery"
        class="position-fixed d-flex flex-column"
        :class="openModal ? '' : 'hide'"
        title="Bildergalerie"
    >
        <template #button>
            <div
                class="fowi-btn fowi-btn-primary fowi-btn-sq48"
                @click="$emit('close')"
            >
                <font-awesome-icon :icon="['far', 'times']" size="2x" />
            </div>
        </template>
        <template #content>
            <div class="d-flex flex-wrap h-100 w-100 p-1">
                <div
                    v-for="canvas in manifest.sequences[0].canvases"
                    :key="'canvas_' + canvas['@id']"
                    class="w-50"
                    @click="selectImage(canvas.thumbnail['@id'])"
                >
                    <img
                        class="h-100 w-100 p-2"
                        :src="canvas.thumbnail['@id']"
                        alt=""
                    />
                </div>
            </div>
        </template>
    </box-wrapper>
</template>

<script>
import BoxWrapper from '@/components/global/BoxWrapper'
import api from '@/mixins/api'

export default {
    name: 'ImageGallery',
    components: { BoxWrapper },
    props: {
        manifest: {
            type: Object,
            default: () => {},
        },
        openModal: {
            type: Boolean,
            default: false,
        },
    },
    data() {
        return {}
    },
    async mounted() {},
    methods: {
        selectImage(imageId) {
            const imageUrl = imageId.replace('!400,400', '!10000,10000')
            this.$emit('selectImage', imageUrl)
        },
    },
}
</script>

<style lang="scss" scoped>
#image-gallery {
    transition: all ease-in-out 250ms;
    pointer-events: none;
    overflow: hidden;
    top: calc(81vw * var(--pxToVw));
    right: calc(50vw * var(--pxToVw));
    z-index: 1;
    opacity: 1;
    pointer-events: all;
    max-height: calc(917vw * var(--pxToVw));

    &.hide {
        pointer-events: none;
        opacity: 0;
        right: -25%;
    }

    img {
        object-fit: contain;
        object-position: top;
    }

    ::-webkit-scrollbar {
        width: calc(16vw * var(--pxToVw));
        height: calc(16vw * var(--pxToVw));
    }

    ::-webkit-scrollbar-track {
        background: var(--lightgrey);
    }

    ::-webkit-scrollbar-thumb {
        background: var(--darkgrey);
    }
}
</style>
<style lang="scss">
#image-gallery {
    h3.title {
        color: var(--white);
    }
}
</style>
