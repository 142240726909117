<template>
    <div
        class="fowi-btn fowi-btn-primary fowi-btn-fixed-size mx-0 my-2 p-0 overflow-hidden"
        :class="{
            active: selected,
        }"
        @click="$emit('click')"
    >
        <svg viewBox="0 0 100 100" class="h-100 w-100">
            <line
                x1="20"
                y1="80"
                x2="80"
                y2="20"
                :stroke="stroke"
                :stroke-width="strokeWidth"
            />
        </svg>
    </div>
</template>

<script>
import HatchingPatternDefs from '@/components/stations/3.005_atelier/handdrawing/HatchingPatternDefs'

export default {
    name: 'DrawLineButton',
    components: { HatchingPatternDefs },
    props: {
        selected: {
            type: Boolean,
            required: true,
        },
        stroke: {
            type: String,
            default: 'black',
        },
        strokeWidth: {
            type: Number,
            default: 0.5,
        },
    },
}
</script>

<style scoped></style>
