<template>
    <div
        class="draw-hatching-btn fowi-btn fowi-btn-primary fowi-btn-fixed-size mx-0 my-2 p-0 overflow-hidden"
        :class="{
            active: selected,
        }"
        @click="$emit('click')"
    >
        <svg class="h-100 w-100">
            <hatching-pattern-defs btn stroke="white" />
            <rect :fill="pattern" x="0" y="0" width="100%" height="100%" />
        </svg>
    </div>
</template>

<script>
import HatchingPatternDefs from '@/components/stations/3.005_atelier/handdrawing/HatchingPatternDefs'

export default {
    name: 'HatchingButton',
    components: { HatchingPatternDefs },
    props: {
        selected: {
            type: Boolean,
            required: true,
        },
        pattern: {
            type: String,
            default: '',
        },
    },
}
</script>

<style lang="scss" scoped></style>
