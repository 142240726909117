<template>
    <defs>
        <pattern
            :id="btn ? 'btnPatternA' : 'patternA'"
            x="0"
            y="0"
            width="10"
            height="10"
            patternTransform="scale(1) rotate(120)"
            patternUnits="userSpaceOnUse"
        >
            <path
                d="M0 10h20z"
                :stroke-width="stroke === 'white' ? 1 : 0.5"
                :stroke="stroke"
                fill="none"
            />
        </pattern>
        <pattern
            :id="btn ? 'btnPatternB' : 'patternB'"
            patternUnits="userSpaceOnUse"
            width="10"
            height="10"
            patternTransform="scale(1) rotate(45)"
        >
            <path
                d="M 10,-2.55e-7 V 20 Z M -1.1677362e-8,10 H 20 Z"
                :stroke-width="stroke === 'white' ? 1 : 0.5"
                :stroke="stroke"
                fill="none"
            />
        </pattern>
        <pattern
            :id="btn ? 'btnPatternC' : 'patternC'"
            x="0"
            y="0"
            width="30"
            height="30"
            patternUnits="userSpaceOnUse"
            patternTransform="scale(.5) rotate(45)"
        >
            <path
                d="M 10,-2.55e-7 V 20 Z M -1.1677362e-8,10 H 20 Z"
                transform="translate(5,0)"
                :stroke-width="stroke === 'white' ? 1 : 0.5"
                :stroke="stroke"
                fill="none"
            />
        </pattern>
        <pattern
            :id="btn ? 'btnPatternD' : 'patternD'"
            x="0"
            y="0"
            width="15"
            height="10"
            patternTransform="scale(1) rotate(120)"
            patternUnits="userSpaceOnUse"
        >
            <path
                d="M0 10h20z"
                transform="translate(5,0)"
                :stroke-width="stroke === 'white' ? 1 : 0.5"
                :stroke="stroke"
                fill="none"
            />
        </pattern>
        <pattern
            :id="btn ? 'btnPatternE' : 'patternE'"
            x="0"
            y="0"
            width="20"
            height="20"
            patternTransform="scale(0.5) rotate(45)"
            patternUnits="userSpaceOnUse"
        >
            <path
                d="M10 15a5 5 0 110-10 5 5 0 010 10z"
                :stroke-width="stroke === 'white' ? 1 : 0.5"
                :stroke="stroke"
                fill="none"
            />
        </pattern>
    </defs>
</template>

<script>
export default {
    name: 'HatchingPatternDefs',
    props: {
        btn: {
            type: Boolean,
            default: false,
        },
        stroke: {
            type: String,
            default: 'black',
        },
    },
}
</script>

<style scoped></style>
