var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"drawing h-100 w-100"},[_c('div',{staticClass:"toolbar d-flex flex-column"},[_c('div',{staticClass:"d-flex"},[_c('div',{staticClass:"d-flex flex-column align-items-center"},_vm._l((_vm.STROKEWIDTHS),function(stroke){return _c('draw-line-button',{key:'stroke-btn-' + stroke,attrs:{"stroke":"white","stroke-width":stroke,"selected":_vm.selectedMode === _vm.MODES.DRAW &&
                            _vm.selectedStrokeWidth === stroke},on:{"click":function($event){return _vm.setStrokeWidth(stroke)}}})}),1),_c('div',{staticClass:"d-flex flex-column align-items-center ml-3"},_vm._l((Object.keys(_vm.BTNFILLS).filter(
                        key => _vm.BTNFILLS[key] !== _vm.BTNFILLS.NONE,
                    )),function(fill){return _c('draw-hatching-button',{key:'fill-btn-' + _vm.BTNFILLS[fill],attrs:{"pattern":_vm.BTNFILLS[fill],"selected":_vm.selectedMode === _vm.MODES.DRAWFILL &&
                            _vm.selectedFill === _vm.FILLS[fill]},on:{"click":function($event){return _vm.setFill(_vm.FILLS[fill])}}})}),1)])]),_c('div',{staticClass:"toolbar-bottom d-flex mt-3"},[(_vm.manifest)?_c('div',{staticClass:"fowi-btn fowi-btn-primary fowi-btn-sq48",class:_vm.manifest.sequences[0].canvases.length <= 1 ||
                _vm.paths.length !== 0
                    ? 'disabled'
                    : '',on:{"click":_vm.toggleImageGallery}},[_c('font-awesome-icon',{attrs:{"icon":['far', 'images'],"size":"lg"}})],1):_vm._e(),_c('div',{staticClass:"fowi-btn fowi-btn-primary fowi-btn-sq48 mx-3",class:_vm.paths.length === 0 ? 'disabled' : '',on:{"click":_vm.toggleVisibility}},[_c('font-awesome-icon',{attrs:{"icon":['far', _vm.visible ? 'eye' : 'eye-slash'],"size":"lg"}})],1),_c('div',{staticClass:"fowi-btn fowi-btn-primary fowi-btn-sq48 mr-3",class:_vm.paths.length === 0 ? 'disabled' : '',on:{"click":_vm.undo}},[_c('font-awesome-icon',{attrs:{"icon":['far', 'undo'],"size":"lg"}})],1),_c('div',{staticClass:"fowi-btn fowi-btn-primary fowi-btn-sq48 mr-3",class:_vm.paths.length === 0 ? 'disabled' : '',on:{"click":_vm.clearConfirm}},[_c('font-awesome-icon',{attrs:{"icon":['far', 'trash'],"size":"lg"}})],1),_c('div',{staticClass:"fowi-btn fowi-btn-primary fowi-btn-sq48 mr-3",class:_vm.paths.length === 0 ? 'disabled' : '',on:{"click":_vm.saveConfirm}},[_c('font-awesome-icon',{attrs:{"icon":['far', 'cloud-upload'],"size":"lg"}})],1)]),_c('div',{staticClass:"slider-container"},[_c('div',{staticClass:"outer-zoom"},[_c('div',{staticClass:"inner-zoom"},[_c('slider',{attrs:{"min":1,"max":2,"step":0.01,"show-dragged-amount":"","hide-label":""},model:{value:(_vm.zoomFactor),callback:function ($$v) {_vm.zoomFactor=_vm._n($$v)},expression:"zoomFactor"}})],1)])]),_c('div',{staticClass:"drawing-container d-flex overflow-hidden"},[_c('div',{staticClass:"drawing-container-canvas",attrs:{"id":"svg-canvas"}},[_c('div',{ref:"canvas",style:({
                    width: _vm.imageWidth ? _vm.imageWidth + 'px' : '100%',
                    height: _vm.imageHeight ? _vm.imageHeight + 'px' : '100%',
                })},[_c('img',{ref:"img",style:(_vm.getImgStyle),attrs:{"src":_vm.imageSrc},on:{"load":_vm.onImageLoad}}),_c('svg',{ref:"svg",style:({
                        width: _vm.imageWidth
                            ? _vm.imageWidth * _vm.zoomFactor + 'px'
                            : '100%',
                        height: _vm.imageHeight
                            ? _vm.imageHeight * _vm.zoomFactor + 'px'
                            : '100%',
                        background: _vm.visible ? 'none' : 'white',
                    }),on:{"mousedown":_vm.onStart,"mousemove":_vm.onDraw,"mouseup":_vm.onEnd,"touchstart":_vm.onStart,"touchmove":_vm.onDraw,"touchend":_vm.onEnd}},[_c('hatching-pattern-defs',{attrs:{"stroke":"black"}}),_vm._l((_vm.paths.filter(
                            path => path.fill === _vm.FILLS.NONE,
                        )),function(path,index){return _c('path',{key:'path_' + index,attrs:{"d":path.dataString,"transform":`scale(${_vm.zoomFactor} ${_vm.zoomFactor})`,"fill":"none","stroke":path.strokeColor,"stroke-width":path.strokeWidth}})}),_vm._l((_vm.paths.filter(
                            path => path.fill !== _vm.FILLS.NONE,
                        )),function(path,index){return _c('path',{key:'hull_' + index,attrs:{"d":path.hullDataString,"transform":`scale(${_vm.zoomFactor} ${_vm.zoomFactor})`,"fill":path.fill,"stroke":path.selected ? 'red' : 'none',"stroke-width":path.selected ? 2 : 0},on:{"touchstart":function($event){return _vm.pickPath(path)}}})})],2)])])]),_c('image-gallery',{attrs:{"open-modal":_vm.imageGallery,"manifest":_vm.manifest},on:{"close":function($event){_vm.imageGallery = false},"selectImage":_vm.changeImageSrc}})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }