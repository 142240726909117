<template>
    <div v-if="image">
        <transition v-if="image" name="fade" mode="out-in">
            <img :key="'image_url' + image.url" :src="image.url" />
        </transition>
    </div>
    <div v-else />
</template>

<script>
export default {
    name: 'AtelierImageCrawler',
    props: {
        images: {
            type: Array,
            default: () => [],
        },
        size: {
            type: String,
            default: '',
        },
    },
    data() {
        return {
            image: null,
            shuffleTimer: null,
        }
    },
    mounted() {
        this.getImage()
    },
    beforeDestroy() {
        clearTimeout(this.shuffleTimer)
    },
    methods: {
        getImage() {
            if (this.image) {
                this.$emit('addImage', this.image)
            }
            const upperLimit = 1.3
            const lowerLimit = 0.7
            let possibleImages = null
            switch (this.size) {
                case 'grid-item-width2':
                    possibleImages = this.images
                        .slice(1)
                        .filter(x => x.properties.dimensions.ratio > upperLimit)
                    break
                case 'grid-item-height2':
                    possibleImages = this.images
                        .slice(1)
                        .filter(x => x.properties.dimensions.ratio < lowerLimit)
                    break
                case 'grid-item-big':
                    this.image = this.images[0]
                    break
                default:
                    possibleImages = this.images
                        .slice(1)
                        .filter(
                            x =>
                                x.properties.dimensions.ratio <= upperLimit &&
                                x.properties.dimensions.ratio >= lowerLimit,
                        )
                    break
            }

            if (possibleImages?.length > 0) {
                const random = Math.floor(Math.random() * possibleImages.length)
                this.image = possibleImages[random]
            }
            if (this.image) {
                this.$emit('removeImage', this.image)
            }
            if (this.image && this.size !== 'grid-item-big') {
                const random = Math.floor(Math.random() * 30) + 5
                this.shuffleTimer = setTimeout(() => {
                    this.getImage()
                }, random * 1000)
            }
        },
    },
}
</script>

<style scoped>
img {
    width: 100%;
    height: 100%;
    padding: 1px;
    background-color: white;
    object-fit: contain;
    border: 1px solid black;
}

div {
    /*background-color: #0c5460;*/
}
</style>
